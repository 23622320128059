
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    onClick: {
      type: Function,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
  },
});
