
import authService from "@/service/api/authService";
import { defineComponent } from "vue";

import { CheckIcon, XIcon } from "@heroicons/vue/outline";

export default defineComponent({
  data() {
    return {
      confirmed: false,
      failed: false,
      message: "Verifying email address..."
    };
  },
  components: {
    CheckIcon,
    XIcon
  },
  async mounted() {
    // Extract token from route URL
    const token = this.$route.query.token;

    // Send token to API
    await authService
      .VerifyEmail(token)
      .then(res => {
        if (res.status === 200) {
          this.message = res.data;
          this.confirmed = true;
        }
      })
      .catch(e => {
        this.message = e.response.data.error;
        this.failed = true;
      });
  }
});
