
import { defineComponent } from "vue";

export default defineComponent({
  setup(props, { emit }) {
    const ok = () => {
      emit("ok");
    };

    const cancel = () => {
      emit("cancel");
    };

    return {
      ok,
      cancel,
    };
  },
});
