
import { defineComponent, onMounted, ref } from "vue";

import BaseModal from "@/components/modals/BaseModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";

import { authenticator } from "@otplib/preset-default";
import qrcode from "qrcode";
import authService from "@/service/api/authService";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    show: Boolean,
  },
  components: {
    BaseModal,
    ErrorModal,
  },
  setup(props, { emit }) {
    const store = useStore();

    const errorPresent = ref(false);
    const errorMessage = ref("");

    const qrCode = ref();
    const totpSecret = ref();
    const totpEnabled = ref(false);
    const token = ref("");
    const totpSuccess = ref(false);
    const totpSuccessDisabled = ref(false);

    const randomSecret = authenticator.generateSecret(16);

    onMounted(async () => {
      // Lookup up the TOTP secret for the user or use the generated one if needed
      try {
        const recovery = await authService.GetRecoveryCodes();
        if (recovery.data.secret) {
          totpEnabled.value = true;
          totpSecret.value = recovery.data.secret;
        } else {
          totpSecret.value = randomSecret;
        }
      } catch (e) {
        // Handle this via a modal
        errorPresent.value = true;
        errorMessage.value = e;
      }

      // Generate scannable QR code
      const otpauth = authenticator.keyuri(
        store.getters.getUsername,
        "Feirm",
        totpSecret.value
      );

      qrCode.value = await qrcode.toDataURL(otpauth);
    });

    // Disabled TOTP for user
    const disableTotp = async () => {
      try {
        await authService.DisableTOTP();
        emit("close");
        totpSuccessDisabled.value = true;
      } catch (e) {
        errorPresent.value = true;
        errorMessage.value = e.response.data.error;
      }

      emit("close");
    };

    // Enable TOTP for user
    const enableTotp = async () => {
      try {
        await authService.EnableTOTP(randomSecret, token.value);
        emit("close");
        totpSuccess.value = true;
      } catch (e) {
        errorPresent.value = true;
        errorMessage.value = e.response.data.error;
      }
    };

    return {
      errorPresent,
      errorMessage,

      totpSecret,
      qrCode,
      totpEnabled,
      token,
      totpSuccess,
      totpSuccessDisabled,

      enableTotp,
      disableTotp,
    };
  },
});
