
import { defineComponent, ref } from "vue";

import SideNav from "@/components/account/SideNav.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";

import authService from "@/service/api/authService";
import account from "@/class/account";

export default defineComponent({
  components: {
    SideNav,
    SuccessModal,
    ErrorModal,
  },
  setup() {
    const currentPassword = ref("");
    const newPassword = ref("");
    const confirmNewPassword = ref("");

    const success = ref({
      show: false,
      message: "",
    });

    const error = ref({
      show: false,
      message: "",
    });

    const isLoading = ref(false);

    // Change password
    const changePassword = async () => {
      // Check that the new passwords match...
      if (newPassword.value !== confirmNewPassword.value) {
        error.value.show = true;
        error.value.message = "New passwords do not match! Please try again.";

        return;
      }

      isLoading.value = true;

      // Fetch the users latest encrypted account
      const res = await authService.GetAccount();
      const encryptedAccount = res.data;

      // Decrypt the account fetched on mount to prove the user owns their password
      let rootKey;
      try {
        rootKey = await account.decryptRootKey(
          currentPassword.value,
          encryptedAccount
        );
      } catch (e) {
        error.value.show = true;
        error.value.message = e;

        isLoading.value = false;

        return;
      }

      // Fetch a password change token
      const response = await authService.ChangePasswordToken();
      const token = response.data;

      // Re-encrypt the root key with the new password
      const newRootKey = await account.generateEncryptedRootKey(
        rootKey,
        newPassword.value
      );

      // Sign the nonce which came with the change password token
      const identityKeypair = await account.deriveIdentityKeypair(rootKey);
      const signedNonce = await account.signMessage(
        identityKeypair,
        token.nonce
      );

      // Now that we have a new root key payload, and a signature of the token nonce, we can construct payload to send back to the change password endpoint.
      const payload = {
        encrypted_key: newRootKey,
        token_id: token.id,
        signature: signedNonce,
      };

      try {
        await authService.ChangePassword(payload);

        success.value.show = true;
        success.value.message =
          "Your Feirm account password has been changed! The next time you login, please remember to use your new password.";

        isLoading.value = false;

        return;
      } catch (e) {
        error.value.show = true;
        error.value.message = e.response.data;

        isLoading.value = false;

        return;
      }
    };

    return {
      isLoading,

      currentPassword,
      newPassword,
      confirmNewPassword,

      changePassword,

      success,
      error,
    };
  },
});
