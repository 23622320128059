
import walletService from "@/service/api/walletService";

import LogoutModal from "@/components/modals/LogoutModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

import { defineComponent, ref } from "vue";
import authService from "@/service/api/authService";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    LogoutModal,
    ConfirmModal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    // Delete/purge user wallet
    const doPurgeWallet = ref(false);

    const togglePurgeWallet = () => {
      doPurgeWallet.value = !doPurgeWallet.value;
    };

    const purgeWallet = async () => {
      try {
        await walletService.RemoveWallet();
        togglePurgeWallet();
      } catch (e) {
        console.log("[Wallet Deletion]:", e);
      }
    };

    // Delete user account
    const doDeleteAccount = ref(false);

    const toggleDeleteAccount = () => {
      doDeleteAccount.value = !doDeleteAccount.value;
    };

    const deleteAccount = async () => {
      try {
        await authService.DeleteAccount();
        toggleDeleteAccount();
        router.push("/");
      } catch (e) {
        console.log("[Account Deletion]:", e);
      }
    };

    const showLogoutModal = ref(false);

    return {
      doPurgeWallet,
      togglePurgeWallet,
      purgeWallet,

      doDeleteAccount,
      toggleDeleteAccount,
      deleteAccount,

      showLogoutModal,
    };
  },
});
