
import { defineComponent, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default defineComponent({
  props: {
    codes: String,
  },
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  setup(props, { emit }) {
    const open = ref(true);

    // Handle close
    const closeEvent = () => {
      emit("close");
    };

    return {
      open,
      closeEvent,
    };
  },
});
