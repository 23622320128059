
import { defineComponent } from "vue";
import {
  GlobeIcon,
  HeartIcon,
  FingerPrintIcon,
  CurrencyPoundIcon,
  CollectionIcon,
  SwitchHorizontalIcon,
  UsersIcon,
  ShoppingBagIcon,
  DatabaseIcon,
  ChatAlt2Icon
} from "@heroicons/vue/outline";
import axios from "axios";

const features = [
  {
    name: "Ultimate security",
    description:
      "Feirm accounts are protected with mandatory two-factor authentication and heavy zero-knowledge encryption. Your data is safe with us.",
    icon: FingerPrintIcon
  },
  {
    name: "Web wallet",
    description:
      "Feirm provides a non-custodial wallet featuring support for many cryptocurrencies and tokens. The private keys remain in your browser whilst we link an encrypted version of your wallet to your Feirm account.",
    icon: CurrencyPoundIcon
  },
  {
    name: "Open source",
    description:
      "Trust is important when it comes to dealing with cryptography, but it isn't enough. The Feirm web-client is open source for everyone to see.",
    icon: HeartIcon
  },
  {
    name: "Available for everyone",
    description:
      "Privacy should be accessible for everyone. Your Feirm account can be accessed on any device with a web browser - no need for more smartphone apps!",
    icon: GlobeIcon
  }
];

const ecosystem = [
  {
    name: "Feirm token",
    description:
      "Feirm has its own utility token called XFE currently operating on the Binance Smart Chain. It is the backbone of the Feirm ecosystem.",
    icon: CollectionIcon
  },
  {
    name: "Bridge",
    description:
      "Stuck on the old Feirm blockchain? Migrate your old native XFE coins to the new token using our bridge. The migration process is instant and automated!",
    icon: SwitchHorizontalIcon
  },
  {
    name: "Social media rewards",
    description:
      "Coming soon, you'll be able to earn additional XFE tokens by completing small tasks on social media. You'll be spreading awareness of the Feirm project and earning at the same time!",
    icon: UsersIcon
  },
  {
    name: "Marketplace",
    description:
      "All users should be able to transact without limits. Soon you will be able to buy/sell digital assets leveraging some of the best decentralised technologies.",
    icon: ShoppingBagIcon
  },
  {
    name: "Interest",
    description:
      "Soon, you can earn more on top of your investment with compound interest just by keeping them in your Platform wallet. No transfers or lock-in required. Your funds only belong to you.",
    icon: DatabaseIcon
  },
  {
    name: "Community",
    description:
      "Whether you are a beginner in the cryptocurrency world, or simply need some support, the Feirm community and support channels have you covered.",
    icon: ChatAlt2Icon
  }
];

export default defineComponent({
  name: "Index",
  data() {
    return {
      articles: []
    };
  },
  async mounted() {
    const MEDIUM_URL = "https://medium.com/feed/feirm";
    const RSS_JSON =
      "https://api.rss2json.com/v1/api.json?rss_url=" + MEDIUM_URL;

    const articles = await axios.get(RSS_JSON);
    this.articles = articles.data.items.splice(0, 3);
  },
  setup() {
    return {
      features,
      ecosystem
    };
  }
});
