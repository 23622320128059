
import { defineComponent } from "vue";
import BaseModal from "@/components/modals/BaseModal.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BaseModal,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const doLogout = () => {
      store.dispatch("logout");
      router.push({ path: "/" });
    };

    return {
      doLogout,
    };
  },
});
