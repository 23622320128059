
import { defineComponent, ref } from "vue";

import SideNav from "@/components/account/SideNav.vue";
import TwoFactorRecoveryCodes from "@/components/account/TwoFactorRecoveryCodes.vue";
import ManageTOTP from "@/components/account/ManageTOTP.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";

import { ExclamationIcon } from "@heroicons/vue/solid";
import authService from "@/service/api/authService";

export default defineComponent({
  components: {
    SideNav,
    TwoFactorRecoveryCodes,
    ManageTOTP,
    ErrorModal,

    ExclamationIcon,
  },
  setup() {
    // Error handling
    interface ErrorPrompt {
      message: string;
      show: boolean;
    }

    const error = ref({} as ErrorPrompt);

    const showManageTotp = ref(false);

    // Recovery codes
    const showRecoveryCodes = ref(false);
    const fetchingRecoveryCodes = ref(false);
    const recoveryCodes = ref();
    const secret = ref();

    const getRecoveryCodes = async () => {
      try {
        fetchingRecoveryCodes.value = true;

        const codes = await authService.GetRecoveryCodes();

        // Throw an error if there are no recovery codes
        if (codes.data.codes === null) {
          fetchingRecoveryCodes.value = false;
          error.value.show = true;
          error.value.message =
            "It looks like there are no recovery codes on record for this account. This is likely due to your account using Email for two-factor authentication rather than a TOTP authenticator app. If you believe this is an error, please contact Feirm support.";
          return;
        }

        recoveryCodes.value = codes.data.codes;
        fetchingRecoveryCodes.value = false;
        showRecoveryCodes.value = true;
      } catch (e) {
        fetchingRecoveryCodes.value = false;
        error.value.show = true;
        error.value.message = e;
      }
    };

    return {
      error,

      showRecoveryCodes,
      fetchingRecoveryCodes,
      getRecoveryCodes,
      recoveryCodes,

      showManageTotp,
      secret,
    };
  },
});
