
import { defineComponent, ref } from "vue";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { ExclamationIcon } from "@heroicons/vue/outline";

export default defineComponent({
  name: "ConfirmModal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    ExclamationIcon
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    confirm() {
      this.$emit("confirmEvent");
    },
    close() {
      this.$emit("close");
    }
  },
  setup() {
    const open = ref(true);

    return {
      open
    };
  }
});
