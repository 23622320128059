
import { defineComponent } from "vue";

import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import account from "./class/account";
import hexToBytes from "./helpers/hexToBytes";

export default defineComponent({
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      registration: null,
      isRefresh: false,
      refreshing: false,
    };
  },
  methods: {
    updateUI(e: CustomEvent) {
      this.registration = e.detail;
      this.isRefresh = true;
    },
    update() {
      this.isRefesh = false;

      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  },
  created() {
    // Check if there is a root key already saved to this device
    const rootKey = localStorage.getItem("rootKey");
    if (rootKey) {
      account.setRootKey(hexToBytes(rootKey));
    }

    // Event listener for service worker
    document.addEventListener("serviceWorkerUpdateEvent", this.updateUI, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }

      this.refreshing = true;
      window.location.reload();
    });
  },
});
