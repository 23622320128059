
import authService from "@/service/api/authService";
import zxcvbn from "zxcvbn";

import { defineComponent } from "vue";
import account from "@/class/account";
import { EncryptedAccount } from "@/models/account";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import bufferToHex from "@/helpers/bufferToHex";
import qrcode from "qrcode";

import { TransitionRoot } from "@headlessui/vue";
import { authenticator } from "@otplib/preset-default";

export default defineComponent({
  name: "Signup",
  components: {
    TransitionRoot
  },
  data() {
    return {
      formStep: 0,
      submitted: false,

      email: "",
      username: "",
      password: "",

      totpSecretQr: "",
      totp: {
        secret: "",
        token: ""
      },

      error: "",
      pwError: "",
      debounce: null
    };
  },
  methods: {
    async usernameValid(username: string) {
      // Check to see if username exists
      // Wrap in a timeout to simulate debounce (refactor this later)
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.error = "";

        try {
          await authService.CheckUsername(username);
        } catch (e) {
          return (this.error = e.response.data.error);
        }
      }, 600);
    },

    checkPassword() {
      // Check password strength with ZXCVBN
      const pw = zxcvbn(this.password);

      const meter = document.getElementById(
        "password-strength-meter"
      ) as HTMLMeterElement;
      meter.value = pw.score;

      switch (pw.score) {
        case 0:
          this.pwError = "Password is very weak!";
          break;

        case 1:
          this.pwError = "Password is weak!";
          break;

        case 2:
          this.pwError = "Password is medium strength!";
          break;

        case 3:
          this.pwError = "Password is strong!";
          break;

        case 4:
          this.pwError = "Password is very strong!";
          break;

        default:
          break;
      }
    },

    async checkForm() {
      // Check if username is empty
      if (!this.username) {
        return this.$toast.error("Username cannot be empty!");
      }

      // Check if passwords are empty
      if (!this.password || !this.confirmPassword) {
        return this.$toast.error("Passwords cannot be empty!");
      }

      // Check if passwords match
      if (this.password != this.confirmPassword) {
        return this.$toast.error("Passwords do not match!");
      }

      // Check if username valid
      this.submitted = true;
      try {
        this.submitted = false;
        await authService.CheckUsername(this.username);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Determine if an email address is present and if not, navigate to TOTP setup
      if (this.email == "") {
        // Generate TOTP secret
        const secret = authenticator.generateSecret(16);
        this.totp.secret = secret;

        // Generate scannable QR code
        const otpauth = authenticator.keyuri(this.username, "Feirm", secret);
        this.totpSecretQr = await qrcode.toDataURL(otpauth);

        return (this.formStep = 1);
      }

      // Othwerise register
      await this.register();
    },

    async register() {
      this.submitted = true;

      // Generate a root key and encrypt it
      const rootKey = account.generateRootKey();
      const encryptedKey = await account.generateEncryptedRootKey(
        rootKey,
        this.password
      );

      // Derive identity keypair
      const keypair = await account.deriveIdentityKeypair(rootKey);

      // Fetch ephemeral token to sign
      const token = await authService.GetRegisterToken();
      const signature = await account.signMessage(keypair, token.data.nonce);

      // Bundle it into an account object
      const encryptedAccount: EncryptedAccount = {
        email: this.email,
        username: this.username,
        identity_publickey: bufferToHex(keypair.getPublic()),
        encrypted_key: encryptedKey,
        token: {
          id: token.data.id,
          signature: signature
        },
        totp: this.totp
      };

      // Submit account object to API
      try {
        const res = await authService.CreateAccount(encryptedAccount);

        // Extract tokens
        const accessToken = res.data.access_token;

        // Set refresh and access token
        this.store.dispatch("login", accessToken);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Decrypt account payload and set the root key
      try {
        const rootKey = await account.decryptRootKey(
          this.password,
          encryptedAccount
        );
        account.setRootKey(rootKey);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e);
      }

      // Fetch and set username in Vuex
      try {
        await authService.GetAccount().then(res => {
          const username = res.data.username;
          this.store.dispatch("setUsername", username);
        });
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Direct to app home
      this.submitted = false;
      this.router.push("/app/");
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    return {
      store,
      router
    };
  }
});
