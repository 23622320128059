
import { defineComponent, ref } from "vue";

import BaseModal from "@/components/modals/BaseModal.vue";
import SideNav from "@/components/account/SideNav.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import account from "@/class/account";
import bufferToHex from "@/helpers/bufferToHex";

export default defineComponent({
  components: {
    BaseModal,
    SideNav,
    ConfirmModal,
  },
  setup() {
    const rootKey = localStorage.getItem("rootKey");
    const savedAccountKey = ref(rootKey ? true : false);

    const openRememberModal = ref(false);

    // Remove key if necessary or show remember me modal
    const toggleRememberModal = () => {
      if (savedAccountKey.value) {
        localStorage.removeItem("rootKey");
        openRememberModal.value = false;
        savedAccountKey.value = false;
      } else {
        openRememberModal.value = true;
      }
    };

    const saveAccountKey = () => {
      localStorage.setItem("rootKey", bufferToHex(account.getRootKey()));
      savedAccountKey.value = true;
      openRememberModal.value = false;
    };

    return {
      openRememberModal,
      toggleRememberModal,
      savedAccountKey,
      saveAccountKey,
    };
  },
});
