
import { onMounted, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionRoot,
} from "@headlessui/vue";
import { Coin, CoinFactory } from "@/class/coins";
import axios from "axios";
import ethWallet from "@/class/wallets/eth-wallet";
import Web3 from "web3";
import { XIcon, CheckIcon } from "@heroicons/vue/outline";
import btcP2wpkhWallet from "@/class/wallets/btc-p2wpkh-wallet";
import sb from "satoshi-bitcoin";
import { TransactionResult } from "@/class/wallets/abstract-wallet";
import { toOutputScript } from "bitcoinjs-lib/src/address";

/*
This component should take in an address for a prop and showcase it, a QR code, and copy to clipboard button
*/

export default {
  props: {
    ticker: String,
  },
  emits: ["close"],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,

    XIcon,
    CheckIcon,
  },
  data() {
    return {
      address: "",
      amount: "0",
    };
  },
  setup(props, { emit }) {
    const open = ref(true);
    const isEth = ref(false);
    const coin = ref({} as Coin);

    // ETH/BSC stuff
    const gasLimit = ref(21000); // 21k gas for standard transaction

    const selectedFee = ref(0);
    const selectedButton = ref(0);
    const userSelectedButton = ref(false);

    const isLoaded = ref(false);
    const sendMax = ref(false);

    const isSubmitted = ref(false);
    const isSending = ref(false);
    const isBroadcast = ref(false);

    const tx = ref({} as TransactionResult);

    interface Fee {
      speed: string;
      amount: string | number;
      format: string;
    }

    const fees: Fee[] = [];

    onMounted(async () => {
      coin.value = CoinFactory.getCoin(props.ticker);
      if (
        coin.value.network === "ethereum" ||
        coin.value.network === "binance"
      ) {
        isEth.value = true;

        // Going to be dealing with tokens, so set a higher gas limit
        if (coin.value.contract) {
          gasLimit.value = 100000;
        }
      }

      // Determine fees for network
      // BTC satoshis per byte gets fetched from https://api.blockchain.info/mempool/fees
      // ETH/BSC can be fetched from Metamask
      if (isEth.value) {
        const web3 = ethWallet.getWeb3(coin.value.network);
        const recommendedFee = await web3.eth.getGasPrice();

        fees.push({
          speed: "Average",
          amount: Web3.utils.fromWei(recommendedFee, "gwei"),
          format: "Gwei",
        });
      } else {
        const fee = await axios.get("https://api.blockchain.info/mempool/fees");

        fees.push(
          {
            speed: "Fast",
            amount: fee.data.priority,
            format: "Sats/b",
          },
          {
            speed: "Average",
            amount: fee.data.regular,
            format: "Sats/b",
          },
          {
            speed: "Slow",
            amount: fee.data.limits.min,
            format: "Sats/b",
          }
        );
      }

      isLoaded.value = true;
    });

    // Create a signed transaction
    const createTx = async (
      address: string,
      amount: number,
      sendMax: boolean
    ) => {
      // Don't allow zero balance
      if (amount == 0 && !sendMax) {
        return alert("Cannot send zero amount!");
      }

      isSubmitted.value = true;

      // If the coin is BTC-based, validate the address and convert the amount into Satoshis
      if (coin.value.network === "bitcoin") {
        // Check that address is valid
        try {
          toOutputScript(address, coin.value.network_data);
        } catch (e) {
          isSubmitted.value = false;
          return alert("Address is not valid!");
        }

        const newAmount = sb.toSatoshi(amount);

        try {
          const signedTx = await btcP2wpkhWallet.createSignedTransaction(
            props.ticker,
            address,
            newAmount,
            selectedFee.value,
            sendMax
          );

          tx.value = signedTx;
          isSubmitted.value = false;
        } catch (e) {
          isSubmitted.value = false;
          console.log(e);
        }
      }

      // If the network is Ethereum or Binance
      if (
        coin.value.network === "ethereum" ||
        coin.value.network === "binance"
      ) {
        // Handle if the token has a contract (token)
        if (coin.value.contract) {
          const signedTx = await ethWallet.sendTokens(
            address,
            amount,
            coin.value.contract,
            selectedFee.value,
            gasLimit.value,
            coin.value.network
          );

          tx.value = signedTx;
        }
        // Otherwise its just a normal transfer
        else {
          const signedTx = await ethWallet.sendCoin(
            address,
            amount,
            selectedFee.value,
            gasLimit.value,
            coin.value.network
          );

          tx.value = signedTx;
        }
      }
    };

    // Open TXID in new tab
    const viewTx = (ticker, txid: string) => {
      const coin = CoinFactory.getCoin(ticker);
      window.open(`${coin.blockbook}/tx/${txid}`, "_blank");
    };

    // Broadcast the raw transaction to correct network
    const broadcastTx = async (ticker, hex: string) => {
      const coin = CoinFactory.getCoin(ticker); // Fetch coin data
      isSending.value = true;

      switch (coin.network) {
        // Broadcast to chain via Blockbook
        case "bitcoin": {
          const blockbook = btcP2wpkhWallet.createBlockbookClient(ticker);

          try {
            await blockbook.sendTx(hex);
          } catch (e) {
            console.error("[Error sending BTC-based TX]:", e);
            isSending.value = false;
          }

          isBroadcast.value = true;

          break;
        }

        // TODO: Tidy this up in future release
        // Broadcast to Ethereum or Binance Smart Chain via Web3
        case "ethereum": {
          const web3 = ethWallet.getWeb3(coin.network);

          try {
            await web3.eth.sendSignedTransaction(tx.value.hex);
          } catch (e) {
            console.error("[Error sending ETH TX]:", e);
            isSending.value = false;
          }

          isBroadcast.value = true;

          break;
        }

        case "binance": {
          const web3 = ethWallet.getWeb3(coin.network);

          try {
            await web3.eth.sendSignedTransaction(tx.value.hex);
          } catch (e) {
            console.error("[Error sending ETH TX]:", e);
            isSending.value = false;
          }

          isBroadcast.value = true;

          break;
        }

        default:
          break;
      }
    };

    // Set a fee (Satoshis for BTC, or Gwei for ETH)
    const setTxFee = (button, fee: number) => {
      selectedFee.value = fee;
      selectedButton.value = button;
      userSelectedButton.value = true;
    };

    // Toggle to send max amount
    const toggleSendMax = () => {
      sendMax.value = !sendMax.value;
    };

    // Handle close
    const closeEvent = () => {
      emit("close");
    };

    return {
      open,
      props,
      coin,
      isEth,
      fees,
      isLoaded,
      isSubmitted,
      selectedButton,
      userSelectedButton,

      gasLimit,

      sendMax,
      toggleSendMax,

      tx,
      isSending,
      isBroadcast,

      createTx,
      broadcastTx,
      viewTx,
      setTxFee,
      closeEvent,
    };
  },
};
