
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { Coin, CoinFactory } from "@/class/coins";

// Components
import AddressModal from "@/components/AddressModal.vue";
import SendModal from "@/components/SendModal.vue";

// Other
import walletService from "@/service/api/walletService";
import account from "@/class/account";
import ethWallet from "@/class/wallets/eth-wallet";
import { EncryptedWallet } from "@/models/wallet";
import btcP2wpkhWallet from "@/class/wallets/btc-p2wpkh-wallet";
import sb from "satoshi-bitcoin";
import Web3 from "web3";

import { QrcodeIcon, ArrowCircleRightIcon } from "@heroicons/vue/outline";

export default defineComponent({
  name: "Wallet",
  components: {
    AddressModal,
    SendModal,

    QrcodeIcon,
    ArrowCircleRightIcon,
  },
  setup() {
    const route = useRoute();
    const ticker: string = route.params.ticker as string;

    const coin = ref({} as Coin);

    const balance = ref();
    const address = ref();

    const showAddressModal = ref(false);
    const showSendModal = ref(false);

    const isLoaded = ref(false);

    onMounted(async () => {
      coin.value = CoinFactory.getCoin(ticker);

      // Fetch and decrypt wallet
      const encryptedWallet: EncryptedWallet = await (
        await walletService.GetWallet()
      ).data;

      const rootKey = account.getRootKey();
      const mnemonic = await ethWallet.decryptWallet(rootKey, encryptedWallet);

      // Set the mnemonic for use with ETH/BSC and Bitcoin
      ethWallet.setMnemonic(mnemonic);
      btcP2wpkhWallet.setMnemonic(mnemonic);

      // Derive and set our receiving address depending on the coin/token
      if (coin.value.network === "bitcoin") {
        const xpub = btcP2wpkhWallet.getZpub(ticker);
        const lastIndex = await btcP2wpkhWallet.getLastIndex(
          ticker,
          xpub,
          false
        );
        address.value = btcP2wpkhWallet.getAddress(ticker, 0, lastIndex);
      } else if (
        coin.value.network === "ethereum" ||
        coin.value.network === "binance"
      ) {
        address.value = ethWallet.getWallet().getAddressString();
      }

      // Fetch balance data for Bitcoin, Ethereum/Binance tokens
      if (coin.value.network === "bitcoin") {
        console.log("[Wallet] Todo: Fetch wallet data for:", coin.value.name);
        const zpub = btcP2wpkhWallet.getZpub(coin.value.ticker);
        const data = await btcP2wpkhWallet.getXpubInfo(zpub, coin.value.ticker);

        balance.value = sb.toBitcoin(
          parseInt(data.balance) + parseInt(data.unconfirmedBalance)
        );
      } else if (
        coin.value.network === "ethereum" ||
        coin.value.network === "binance"
      ) {
        console.log("[Wallet] Todo: Fetch token data for:", coin.value.name);
        if (coin.value.contract) {
          const contract = ethWallet.getContract(
            coin.value.contract,
            coin.value.network
          );
          const contractBalance = await contract.methods
            .balanceOf(address.value)
            .call();

          balance.value = Web3.utils.fromWei(contractBalance, "ether");
        } else {
          const web3 = ethWallet.getWeb3(coin.value.network);
          const weiBalance = await web3.eth.getBalance(address.value);

          balance.value = Web3.utils.fromWei(weiBalance, "ether");
        }
      }

      isLoaded.value = true;
    });

    // Open receiving address modal
    const toggleReceivingAddressModal = () => {
      showAddressModal.value = !showAddressModal.value;
    };

    // Toggle send modal
    const toggleSendModal = () => {
      showSendModal.value = !showSendModal.value;
    };

    return {
      coin,
      ticker,
      address,
      balance,

      showAddressModal,
      showSendModal,

      isLoaded,

      toggleReceivingAddressModal,
      toggleSendModal,
    };
  },
});
