
import { defineComponent } from "vue";
import BaseModal from "@/components/modals/BaseModal.vue";

export default defineComponent({
  name: "SuccessModal",
  components: {
    BaseModal,
  },
  props: {
    show: {
      type: Boolean,
    },
    message: {
      type: String,
    },
  },
});
