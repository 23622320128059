
import { onMounted, reactive, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ClipboardCopyIcon,
  ClipboardIcon,
  XIcon,
} from "@heroicons/vue/outline";
import qrcode from "qrcode";
import { CoinFactory } from "@/class/coins";

/*
This component should take in an address for a prop and showcase it, a QR code, and copy to clipboard button
*/

export default {
  props: {
    address: String,
    ticker: String,
  },
  emits: ["close"],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,

    XIcon,
    ClipboardCopyIcon,
    ClipboardIcon,
  },
  setup(props, { emit }) {
    const open = ref(true);
    const copied = ref(false);

    const coin = CoinFactory.getCoin(props.ticker);

    const addressData = reactive({
      string: "",
      qr: "",
    });

    onMounted(async () => {
      // Create QR code of address value
      const qrCode = await qrcode.toDataURL(props.address, {
        width: 256,
        height: 256,
      });

      // Set address and QR
      addressData.string = props.address;
      addressData.qr = qrCode;
    });

    // Copy the address to clipboard
    const copyToClipboard = async (address: string) => {
      if (!navigator.clipboard) {
        throw new Error("Unable to access clipboard!");
      }

      try {
        await navigator.clipboard.writeText(address);
        copied.value = true;

        // Reset copy status after 1 second
        setTimeout(() => {
          copied.value = false;
        }, 1000);
      } catch (e) {
        throw new Error(e);
      }
    };

    // Handle close
    const closeEvent = () => {
      emit("close");
    };

    return {
      open,
      addressData,
      copied,
      coin,

      copyToClipboard,
      closeEvent,
    };
  },
};
