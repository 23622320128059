
import { defineComponent } from "vue";
import { mapActions, useStore } from "vuex";
import { useRouter } from "vue-router";

import tatsuyaService from "@/service/api/tatsuyaService";
import { ModeOfOperation, utils } from "aes-js";
import { ArgonType, hash } from "argon2-browser";
import hexToBytes from "@/helpers/hexToBytes";
import bufferToHex from "@/helpers/bufferToHex";
import account from "@/class/account";

import qrcode from "qrcode";
import { authenticator } from "@otplib/preset-default";
import authService from "@/service/api/authService";
import { EncryptedAccount } from "@/models/account";

export default defineComponent({
  name: "Migrate",
  data() {
    return {
      username: "",
      password: "",
      pin: "",

      rootKey: "",

      email: "",
      newPassword: "",
      newConfirmPassword: "",

      totpSecretQr: "",
      totp: {
        secret: "",
        token: ""
      },

      submitted: false,
      step: 0
    };
  },
  methods: {
    ...mapActions(["login"]),

    async migrateRequest() {
      // Set the submission state to true
      this.submitted = true;

      // Fetch account for the supplied username and PIN
      let oldAccount;
      try {
        await tatsuyaService
          .fetchEncryptedAccount(this.username, this.pin)
          .then(res => {
            oldAccount = res.data;
            this.submitted = false;
          });
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Reconstruct the encryption key used to encrypt the root key
      let encryptionKey;
      try {
        const secretKey = await hash({
          pass: this.password,
          salt: hexToBytes(oldAccount.rootPasswordSalt),
          type: ArgonType.Argon2id,
          hashLen: 32
        });

        encryptionKey = secretKey.hash;
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e);
      }

      // Attempt to decrypt the root key
      let rootKey;
      try {
        const salt = utils.hex
          .toBytes(oldAccount.encryptedRootKey.iv)
          .slice(0, 16);
        const aesCbc = new ModeOfOperation.cbc(encryptionKey, salt);

        rootKey = aesCbc.decrypt(
          utils.hex.toBytes(oldAccount.encryptedRootKey.cipherText)
        );

        this.rootKey = bufferToHex(rootKey);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e);
      }

      // Derive an identity keypair to check if public keys match
      const keypair = await account.deriveIdentityKeypairLegacy(rootKey);
      const publicKey = bufferToHex(keypair.publicKey);

      if (oldAccount.rootPublicKey !== publicKey) {
        return this.$toast.error("Please check your password and try again!");
      }

      // Proceed to next step
      this.step++;
    },

    async checkForm() {
      // Check if username is empty
      if (!this.username) {
        return this.$toast.error("Username cannot be empty!");
      }

      // Check if passwords are empty
      if (!this.newPassword || !this.newConfirmPassword) {
        return this.$toast.error("Passwords cannot be empty!");
      }

      // Check if passwords match
      if (this.newPassword != this.newConfirmPassword) {
        return this.$toast.error("Passwords do not match!");
      }

      // Check if username valid
      this.submitted = true;
      try {
        this.submitted = false;
        await authService.CheckUsername(this.username);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Determine if an email address is present and if not, navigate to TOTP setup
      if (this.email == "") {
        // Generate TOTP secret
        const secret = authenticator.generateSecret(16);
        this.totp.secret = secret;

        // Generate scannable QR code
        const otpauth = authenticator.keyuri(this.username, "Feirm", secret);
        this.totpSecretQr = await qrcode.toDataURL(otpauth);

        console.log(this.totp);

        return (this.step = 2);
      }

      // Othwerise register
      await this.register();
    },

    async register() {
      this.submitted = true;

      // Generate a root key and encrypt it
      const rootKey = account.generateRootKey();
      const encryptedKey = await account.generateEncryptedRootKey(
        rootKey,
        this.password
      );

      // Derive identity keypair
      const keypair = await account.deriveIdentityKeypair(rootKey);

      // Fetch ephemeral token to sign
      const token = await authService.GetRegisterToken();
      const signature = await account.signMessage(keypair, token.data.nonce);

      // Bundle it into an account object
      const encryptedAccount: EncryptedAccount = {
        email: this.email,
        username: this.username,
        identity_publickey: bufferToHex(keypair.getPublic()),
        encrypted_key: encryptedKey,
        token: {
          id: token.data.id,
          signature: signature
        },
        totp: this.totp
      };

      // Submit account object to API
      try {
        const res = await authService.CreateAccount(encryptedAccount);

        // Extract tokens
        const accessToken = res.data.access_token;

        // Set refresh and access token
        this.login(accessToken);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Decrypt account payload and set the root key
      try {
        const rootKey = await account.decryptRootKey(
          this.password,
          encryptedAccount
        );
        account.setRootKey(rootKey);
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e);
      }

      // Fetch and set username in Vuex
      try {
        await authService.GetAccount().then(res => {
          const username = res.data.username;
          this.store.dispatch("setUsername", username);
        });
      } catch (e) {
        this.submitted = false;
        return this.$toast.error(e.response.data.error);
      }

      // Direct to app home
      this.submitted = false;
      this.router.push("/app/");
    }
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    return {
      router,
      store
    };
  }
});
