
import { CloudIcon, LockClosedIcon, EyeOffIcon } from "@heroicons/vue/outline";
import { CoinFactory } from "@/class/coins";

const features = [
  {
    name: "Non-custodial web wallet",
    description:
      "Generated and encrypted in the browser, your wallet is untouchable by us, but available to every device you log in with your Feirm account.",
    icon: CloudIcon,
  },
  {
    name: "Encryption",
    description:
      "Feirm accounts have been designed to always remain encrypted throughout the platform. We never come into contact with your password.",
    icon: LockClosedIcon,
  },
  {
    name: "Privacy",
    description:
      "All you need to get started is a username and strong password. Providing an email address is optional. We want to know as little as possible about you!",
    icon: EyeOffIcon,
  },
];

const tokens = CoinFactory.getCoins();

export default {
  setup() {
    return {
      features,
      tokens,
    };
  },
};
