
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

import ETHWallet from "@/class/wallets/eth-wallet";
import account from "@/class/account";
import walletService from "@/service/api/walletService";

export default defineComponent({
  data() {
    return {
      mnemonic: ""
    };
  },
  methods: {
    async next() {
      // Create the wallet from the mnemonic
      try {
        ETHWallet.setMnemonic(this.mnemonic);
      } catch (e) {
        return this.$toast.error(e);
      }

      // Encrypt wallet
      const rootKey = account.getRootKey();
      const encryptedWallet = await ETHWallet.encrypt(rootKey);

      // Upload encrypted wallet to API
      try {
        await walletService.AddWallet(encryptedWallet);
      } catch (e) {
        return this.$toast.error(e.response.data.error);
      }

      this.router.push("/app/wallet");
    }
  },
  setup() {
    const router = useRouter();

    return {
      router
    };
  }
});
