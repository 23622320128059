
import { defineComponent, onMounted, ref, watch } from "vue";
import { XIcon } from "@heroicons/vue/outline";

// Footer components
import OK from "@/components/modals/footers/OK.vue";
import Dismiss from "@/components/modals/footers/Dismiss.vue";

export default defineComponent({
  name: "ModalDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hideCloseIcon: {
      type: Boolean,
      default: false,
    },

    // Footer type props
    okFooter: {
      type: Boolean,
      default: false,
    },
    dismissFooter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OK,
    Dismiss,

    XIcon,
  },
  setup(props, { emit }) {
    const showModal = ref(false);

    const closeModal = () => {
      showModal.value = false;
      emit("close");
    };

    onMounted(() => {
      const onEscape = (e: any) => {
        if (e.keyCode === 27) {
          closeModal();
        }
      };

      document.addEventListener("keydown", onEscape);
    });

    watch(
      () => props.show,
      (show) => {
        showModal.value = show;
      }
    );

    return {
      closeModal,

      showModal,
    };
  },
});
